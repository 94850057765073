import React, { useState, useEffect } from "react";
import "./CyberSecurity.scss";
import blob from "./blob.svg";
import heroImg from "./cyber-header-img.png";
import glass from "./glass.svg";
import { logo, solutions } from "./data";
import elipse from "./cyber-elipse.svg";
import enquiry from "./enquiry-btn.svg";
import cyberImg from "./cyber-contact_img.png";
import line from "./cyber-line-1.png";

import { AnimatePresence, motion } from "framer-motion";
import { isVisible } from "@testing-library/user-event/dist/utils";

function CyberSecurity() {
  const [crrntSlide, setCrrntSlide] = useState(1);

  const nextSlide = () => {
    if (crrntSlide !== solutions.length) {
      setCrrntSlide(crrntSlide + 1);
    } else if (crrntSlide === solutions.length) {
      setCrrntSlide(1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
  }, [crrntSlide]);

  const [tabId, setTabId] = useState(1);

  // const handleClick = (itemId) => {
  //   useList(itemId);
  // };

  useEffect(() => {
    document.title = "Cyber Security | TS Qatar";
  }, []);

  console.log(tabId);

  return (
    <div className="container cyber">
      <img src={line} alt="" className="cyber-line" />
      <section className="cyber-header">
        <div className="cyber-header_content">
          <h1>
            PROTECT YOURSELF <br />
            FROM ATTACK
          </h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </p>

          <button class="learn-more">
            <span class="circle" aria-hidden="true">
              <span class="icon arrow"></span>
            </span>
            <span class="button-text">Learn More</span>
          </button>
        </div>
        <div className="cyber-header_blob">
          <div className="cyber-blob">
            {/* <div
            className="cyber-blob"
            style={{
              backgroundImage: `url(${blob})`,
            }}
          > */}
            <img src={heroImg} alt="" />
          </div>
        </div>
      </section>
      <section className="cyber-brand">
        <h2>
          Guarding The Industry's Top Apps And Games. From Innovative Startups
          To Renowned Enterprises.
        </h2>
        <div className="cyber-brand_icons">
          {logo.map((item) => (
            <li key={item.id}>
              <motion.div
                animate={{ opacity: 1 }}
                transition={{
                  duration: 1,
                  delay: item.delay,
                  ease: [0.5, 0.71, 1, 1.5],
                }}
                initial={{ opacity: 0 }}
                className="cyber-brand_elements"
                style={{ backgroundImage: `url(${glass})` }}
              >
                <img src={item.img} alt={item.name} />
              </motion.div>
            </li>
          ))}
        </div>
      </section>
      <section className="cyber-solutions">
        <div className="cyber-solutions-list">
          {solutions.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                setTabId(item.id);
              }}
            >
              <div
                className="cyber-solutions_elements"
                style={{ backgroundImage: `url(${elipse})` }}
              >
                <img src={item.img} alt={item.name} />
              </div>
              <div className="cyber-solutions-list-content">
                <h2>{item.heading} </h2>
                <p> {item.subtitle}</p>
              </div>
            </li>
          ))}
        </div>
        <div className="cyber-solutions-content">
          {solutions.map(
            (item) =>
              item.id === tabId && (
                <div key={item.id}>
                  <motion.h2
                    initial={{ opacity: 0 }}
                    transition={{ delay: 0.2 }}
                    animate={{ opacity: 1 }}
                  >
                    {item.contentHead}
                  </motion.h2>
                  <motion.p
                    initial={{ opacity: 0 }}
                    transition={{ delay: 0.3 }}
                    animate={{ opacity: 1 }}
                  >
                    {item.contnentPara}
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0 }}
                    transition={{ delay: 0.4 }}
                    animate={{ opacity: 1 }}
                  >
                    {item.contnentBullets}
                  </motion.p>
                  <motion.button
                    initial={{ opacity: 0 }}
                    transition={{ delay: 0.5 }}
                    animate={{ opacity: 1 }}
                  >
                    {item.contentBtn}
                  </motion.button>
                </div>
              )
          )}
        </div>
      </section>
      <motion.section className="cyber-solutions_slide">
        {solutions.map(
          (item) =>
            item.id === crrntSlide && (
              <div key={item.id}>
                <h2>{item.contentHead}</h2>
                <p>{item.contnentPara}</p>
                <p>{item.contnentBullets}</p>
                <motion.button>{item.contentBtn}</motion.button>
              </div>
            )
        )}
      </motion.section>
      <section className="cyber-contact">
        <h2>We Help Client Partners Around The World</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s,
        </p>
        <div className="cyber-contact-box">
          <div className="cyber-contact-box-image">
            <img src={cyberImg} alt="" />
          </div>
          <div className="cyber-contact-box-content">
            <h3>Explore Valuable Cybersecurity Resources</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <button style={{ backgroundImage: `url(${enquiry})` }}>
              Enquiry
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CyberSecurity;
