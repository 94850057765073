import SophosImg from "./sophos-white.svg";
import fortinetImg from "./fortinet-white.svg";
import kasperskyImg from "./kaspersky-white.svg";
import veritasImg from "./veritas-white.svg";
import securityImg from "./cyber-icon.svg";

export const logo = [
  {
    id: 1,

    name: "Sophos",

    img: SophosImg,

    delay: 0.5,
  },

  {
    id: 2,

    name: "Fortinet",

    img: fortinetImg,

    delay: 0.7,
  },

  {
    id: 3,

    name: "Kaspersky",

    img: kasperskyImg,

    delay: 0.9,
  },

  {
    id: 4,

    name: "Veritas",

    img: veritasImg,

    delay: 1.1,
  },
];

export const solutions = [
  {
    id: 1,
    heading: "Cyber Security Solutions",
    subtitle: "Lorem Ipsum is simply dummy text of the printing industry.",
    img: securityImg,
    contentHead: "The Perfect Solution For All The Protection Question",
    contnentPara:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    contnentBullets: "99% Malware Detection Removal",
    contentBtn: "Know More",
  },
  {
    id: 2,
    heading: "Heading 2",
    subtitle: "Lorem Ipsum is simply dummy text of the printing industry.",
    img: securityImg,
    contentHead: "The Perfect Heading 2",
    contnentPara:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    contnentBullets: "99% Malware Detection Removal",
    contentBtn: "Know More",
  },
  {
    id: 3,
    heading: "Heading 3",
    subtitle: "Lorem Ipsum is simply dummy text of the printing industry.",
    img: securityImg,
    contentHead: "The Perfect Heading 3",
    contnentPara:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    contnentBullets: "99% Malware Detection Removal",
    contentBtn: "Know More",
  },
];
