import React from "react";
import "./Partners.scss";

function Partners() {
  return (
    <div className="container">
      <section class="partners-page">
        <div class="partners-page-box">
          <div class="partners-page-content">
            <h1>Partners with TS Qatar</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem ipsum is simply dummytext of the printing and
              typesetting industry.
            </p>
          </div>
          <div class="partners-page-icon">
            <div class="partners-page-icon-raw">
              <img src="assets/images/partners-page/apc-partner.png" alt="" />
              <img
                src="assets/images/partners-page/alienvault-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/acunetix-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/acronis-partner.png"
                alt=""
              />
              <img src="assets/images/partners-page/aruba-partner.png" alt="" />
            </div>
            <div class="partners-page-icon-raw">
              <img src="assets/images/partners-page/avaya-partner.png" alt="" />
              <img src="assets/images/partners-page/cisco-partner.png" alt="" />
              <img
                src="assets/images/partners-page/citrix-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/crowdstrike-partner.png"
                alt=""
              />
              <img src="assets/images/partners-page/dell-partner.png" alt="" />
            </div>
            <div class="partners-page-icon-raw">
              <img src="assets/images/partners-page/duo-partner.png" alt="" />
              <img
                src="assets/images/partners-page/fortinet-partner.png"
                alt=""
              />
              <img src="assets/images/partners-page/gfi-partner.png" alt="" />
              <img src="assets/images/partners-page/hpe-paartner.png" alt="" />
              <img
                src="assets/images/partners-page/huawei-partner.png"
                alt=""
              />
            </div>
            <div class="partners-page-icon-raw">
              <img
                src="assets/images/partners-page/kaspersky-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/linkshadow-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/lenovo-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/meraki-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/microsoft-partner.png"
                alt=""
              />
            </div>
            <div class="partners-page-icon-raw">
              <img
                src="assets/images/partners-page/paloalto-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/panduit-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/redhat-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/redware-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/sophos-partner.png"
                alt=""
              />
            </div>
            <div class="partners-page-icon-raw">
              <img
                src="assets/images/partners-page/symantec-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/synology-partner.png"
                alt=""
              />
              <img src="assets/images/partners-page/trend-partner.png" alt="" />
              <img
                src="assets/images/partners-page/umbrella-partner.png"
                alt=""
              />
              <img src="assets/images/partners-page/veeam-partner.png" alt="" />
            </div>
            <div class="partners-page-icon-raw">
              <img
                src="assets/images/partners-page/veritas-partner.png"
                alt=""
              />
              <img
                src="assets/images/partners-page/vmware-partner.png"
                alt=""
              />
              <img src="assets/images/partners-page/webex-partner.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Partners;
