import ExcelImg from "./excel-img.svg"
import ThreeImg from "./three-img.svg"
import PowerImg from "./power-img.svg"
import PrdctImg1 from "./excel-prdct.svg"
import PrdctImg2 from "./word-prdct.svg";
import PrdctImg3 from "./outlook-prdct.svg";
import PrdctImg4 from "./teams-prdct.svg";
import PrdctImg5 from "./onedrive-prdct.svg";
import PrdctImg6 from "./365-prdct.svg";
import PrdctImg7 from "./power-prdct.svg";
import PrdctImg8 from "./azure-prdct.svg";


export const slide = [
  {
    id: 1,
    bgIcon: "linear-gradient(180deg, #21A367 0%, #33C481 64.06%)",
    img: ExcelImg,
    title: "Microsoft Excel",
    bgBtn: "#27AE70",
    btn: "Shop Now",
  },
  {
    id: 2,
    bgIcon: "linear-gradient(180deg, #9152D3 0%, #D3B5F3 100%)",
    img: ThreeImg,
    title: "Microsoft 365",
    bgBtn: "#A376D3",
    btn: "Shop Now",
  },
  {
    id: 3,
    bgIcon: "linear-gradient(180deg, #EFB300 0%, #F7EAB0 100%)",
    img: PowerImg,
    title: "Microsoft Power BI",
    bgBtn: "#ECBC28",
    btn: "Shop Now",
  },
];

export const product = [
  {
    id: 1,
    icon: PrdctImg1,
    name: "Excel",
  },
  {
    id: 2,
    icon: PrdctImg2,
    name: "Word",
  },
  {
    id: 3,
    icon: PrdctImg3,
    name: "Outlook",
  },
  {
    id: 4,
    icon: PrdctImg4,
    name: "Teams",
  },
  {
    id: 5,
    icon: PrdctImg5,
    name: "Cloud",
  },
  {
    id: 6,
    icon: PrdctImg6,
    name: "365",
  },
  {
    id: 7,
    icon: PrdctImg7,
    name: "Power BI",
  },
  {
    id: 7,
    icon: PrdctImg8,
    name: "Azure",
  },
];

export const packageCard = [
  {
    title: "Windows",
    cntnt: "Lorem Ipsum is simply dummy text of the printing",
    bullet1: "Lorem Ipsum is simply",
    bullet2: "Lorem Ipsum is simply",
    bullet3: "Lorem Ipsum is simply",
    bullet4: "Lorem Ipsum is simply",
  },
];