import React, { useState, useEffect } from "react";
import "./Microsoft.scss";
import Shadow from "./shadow.svg";
import CloudImg from "./cloud-img.png";
import { packageCard, product, slide } from "./data";
import { AnimatePresence, motion } from "framer-motion";
import PackageCard from "../../Components/PackageCard/PackageCard";

function Microsoft(props) {
  const [crrntSlide, setCrrntSlide] = useState(1);

  const nextSlide = () => {
    if (crrntSlide !== slide.length) {
      setCrrntSlide(crrntSlide + 1);
    } else if (crrntSlide === slide.length) {
      setCrrntSlide(1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, [crrntSlide]);

  return (
    <div
      className="container microsoft"
      style={{ backgroundImage: `url(${Shadow})` }}
    >
      <section className="microsoft-header">
        <div className="microsoft-header_content">
          <h1>The Future Of Cloud Solutions</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type.
          </p>
          <button className="button-48" role="button">
            <span className="text">Explore</span>
          </button>
        </div>
        <div className="microsoft-header_slide">
          {slide.map(
            (item, id) =>
              item.id === crrntSlide && (
                <div className="microsoft-header_slide_box" key={id}>
                  <motion.div
                    initial={{
                      y: "-100%",
                      opacity: 0,
                    }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    className="microsoft-header_slide_icon"
                    style={{ background: item.bgIcon }}
                  ></motion.div>
                  <motion.img
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    src={item.img}
                    alt=""
                    className="microsoft-header_slide_img"
                  />
                  <div className="microsoft-header_slide_content">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 1, duration: 2 }}
                    >
                      <h1>{item.title}</h1>
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 1.2, duration: 1 }}
                    >
                      <button style={{ background: item.bgBtn }}>
                        {item.btn}
                      </button>
                    </motion.div>
                  </div>
                </div>
              )
          )}
        </div>
      </section>
      <section className="microsoft-product">
        {product.map((item, id) => (
          <div className="microsoft-product_icon">
            <div className="microsoft-product_icon-img">
              <img src={item.icon} alt="" />
            </div>
            <div>
              <p>{item.name}</p>
            </div>
          </div>
        ))}

        {/* <div className="microsoft-product_icon">
          {product.map((item, id) => (
            <div>
              <img src={item.icon} alt="" />
            </div>
          ))}
          {product.map((item, id) => (
            <div>
              <p>{item.name}</p>
            </div>
          ))}
        </div> */}
        {/* <div className="microsoft-product_icon2">
          {product.map((item, id) => (
            <div className="microsoft-product_icon2_box">
              <img src={item.icon} alt="" />
              <p>{item.name}</p>
            </div>
          ))}
        </div> */}
      </section>
      <section className="microsoft-cloud">
        <div className="microsoft-cloud_content">
          <h1>Cloud Solutions</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type.
          </p>
          <button>Explore</button>
        </div>
        <div className="microsoft-cloud_image">
          <img src={CloudImg} alt="" />
        </div>
      </section>
      <section className="microsoft-package">
        <h1>Upgrade Your Package</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the a galley of type.
        </p>
        <div className="microsoft-package_card">
          {packageCard.map((item, id) => (
            <PackageCard
              key={item.id}
              head={item.title}
              cntnt={item.cntnt}
              bullet1={item.bullet1}
              bullet2={item.bullet2}
              bullet3={item.bullet3}
              bullet4={item.bullet4}
            />
          ))}
          {packageCard.map((item, id) => (
            <PackageCard
              key={item.id}
              head={item.title}
              cntnt={item.cntnt}
              bullet1={item.bullet1}
              bullet2={item.bullet2}
              bullet3={item.bullet3}
              bullet4={item.bullet4}
            />
          ))}
          {packageCard.map((item, id) => (
            <PackageCard
              key={item.id}
              head={item.title}
              cntnt={item.cntnt}
              bullet1={item.bullet1}
              bullet2={item.bullet2}
              bullet3={item.bullet3}
              bullet4={item.bullet4}
            />
          ))}
        </div>
      </section>
    </div>
  );
}
export default Microsoft;
