import "./App.css";
import "./Pages/index";
import "./Components/index";
import CyberSecurity from "./Pages/CyberSecurity/CyberSecurity";
import Microsoft from "./Pages/Microsoft/Microsoft";

import Contact from "./Pages/Contact/Contact";
import Partners from "./Pages/Partners/Partners";
import Services from "./Pages/Services/Services";

import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          {/* <Route exact index path="/" element={<Home />} /> */}
          <Route exact path="/shop" element="https://eshop.tsqatar.com/" />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/partners" element={<Partners />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/" index element={<CyberSecurity />} />
          <Route exact path="/microsoft" element={<Microsoft />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default App;
