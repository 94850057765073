import React from "react";
import "./Services.scss";

function Services() {
  return (
    <div className="container">
      <section class="services-page">
        <div class="services-page-header">
          <img src="assets/images/Services/header-image.png" alt="" />
          <div class="services-page-header-content">
            <h2>
              Build Your Business <br /> With <span>TS Qatar</span>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </p>
            <div class="services-page-header-btn">
              <button>Get Ready</button>
              <button>Learn More</button>
            </div>
          </div>
        </div>
      </section>

      <section class="services-review">
        <div class="services-review-content">
          <h3>
            <b>What our customers</b> <br />
            say about us
          </h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum is simply.
          </p>
          <div class="services-review-person">
            <div class="services-review-name">
              <p>
                {" "}
                <b>Jhon Antony</b> <br /> Project Manager
              </p>
            </div>
            <div class="services-review-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="services-review-image">
          <img src="assets/images/Services/Jhon.png" alt="" />
        </div>
      </section>

      <section className="services-boxes">
        <h3>We will help you with our creative thinking</h3>
        <div className="services-boxes-box">
          <div className="services-boxes-box-content">
            <h5>Computer Network Infrastructure Solutions</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>Structured Cabling (Ooredoo Certified)</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>Surveillance and Access Control</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="services-boxes-box">
          <div className="services-boxes-box-content">
            <h5>
              Datacenter <br />
              Solutions
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>Video Conferencing Solutions</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>
              IT Security <br />
              Solutions
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="services-boxes-box">
          <div className="services-boxes-box-content">
            <h5>
              Support <br />
              Contract
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>IP Telephony and Contact Center</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>
              IT Power <br />
              Solutions
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="services-boxes-box">
          <div className="services-boxes-box-content">
            <h5>
              PC <br />
              Accessories
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          <div className="services-boxes-box-content">
            <h5>
              Managed <br />
              Services
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>
          {/*
          <div className="services-boxes-box-content">
            <h5>
              IT Power <br />
              Solutions
            </h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum is simply dummy.
            </p>
            <div class="services-boxes-icon">
              <img src="assets/images/landing-page/right-arrow.svg" alt="" />
            </div>
          </div>{" "}
          */}
        </div>
      </section>
    </div>
  );
}
export default Services;
