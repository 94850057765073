import React from "react";
import "./PackageCard.scss";

function PackageCard(props) {
  return (
    <div className="container-package">
      <div className="package-card">
              <h5>{props.head}</h5>
              <p>{props.cntnt}</p>
        <ul>
                  <li>{props.bullet1}</li>
                  <li>{props.bullet2}</li>
                  <li>{props.bullet3}</li>
                  <li>{props.bullet4}</li>
              </ul>
              <button>Get Started {props.btn}</button>
      </div>
    </div>
  );
}
export default PackageCard;
