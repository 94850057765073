import React, { useState } from "react";
import "./Navbar.scss";
import Logo from "./logo.png";
import Menu from "./menu.svg";
import Close from "./close.svg";
import { NavLink, useLocation } from "react-router-dom";
import { color } from "framer-motion";

function Navbar() {
  const location = useLocation();
  console.log(location, "loc");

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    console.log(isNavOpen);
  };

  // const bgcolor = () => {
  let bgColor;
  if (location.pathname == "/") {
    bgColor = "#0d0d0d";
  } else {
    bgColor = "#002538";
  }

  return (
    <>
      <div className="navbar">
        <nav style={{ backgroundColor: bgColor }}>
          <img src={Logo} className="navbar-logo" alt="Logo" />
          <div className="menu-icon">
            <img src={isNavOpen ? Close : Menu} alt="" onClick={toggleNav} />
          </div>
          <div
            className={`navbar-blur ${isNavOpen ? "active" : "hidden"}`}
          ></div>
          <ul className={`navbar-links ${isNavOpen ? "active" : "hidden"}`}>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="https://eshop.tsqatar.com/">Shop</NavLink>
            </li>
            <li>
              <NavLink to="https://tsqatar.com/services/">Services</NavLink>
            </li>
            <li>
              <NavLink to="https://tsqatar.com/partners/">Partners</NavLink>
            </li>
            <li>
              <NavLink to="https://tsqatar.com/contact-us/">Contact Us</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
export default Navbar;
