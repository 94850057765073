import React from "react";
import "./Webapp.scss";

function Webapp() {
  return (
    <div className="container">
      <section class="webapp-header">
        <div class="webapp-header-box">
          <div class="webapp-header-content">
            <h1>
              Web Application <br /> Firewall
            </h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has dummy text of the printing
            </p>
          </div>
          <div class="webapp-header-img">
            <img
              src="assets/images/webapplications-page/Webapp-header-img.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section class="webapp-waf">
        <div class="webapp-waf-box">
          <div class="webapp-waf-content">
            <h1>What is WAF?</h1>
            <p>
              A WAF creates a shield between a web app and the Internet; this
              shield can help mitigate many common attacks.
            </p>
          </div>
          <div class="webapp-waf-icons">
            <img src="assets/images/partners-page/sophos-partner.png" alt="" />
            <img
              src="assets/images/partners-page/paloalto-partner.png"
              alt=""
            />
            <img
              src="assets/images/partners-page/acunetix-partner.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section class="webapp-about">
        <div class="webapp-about-box">
          <div class="webapp-about-img">
            <img
              src="assets/images/webapplications-page/webapp-about-img1.png"
              alt=""
            />
          </div>
          <div class="webapp-about-content">
            <h3>About</h3>
            <p>
              WAFs are designed to protect web applications/servers from
              web-based attacks that IPSs cannot prevent. Where IPSs interrogate
              traffic against signatures and anomalies, WAFs interrogate the
              behavior and logic of what is requested and returned. WAFs protect
              against web application threats like SQL injection, cross-site
              scripting, session hijacking, parameter or URL tampering and
              buffer overflows. They do so in the same manner an IPS does, by
              analyzing the contents of each incoming and outgoing packet.WAFs
              are typically deployed in some sort of proxy fashion just in front
              of the web applications, so they do not see all traffic on our
              networks. By monitoring the traffic before it reaches the web
              application, WAFs can analyze requests before passing them on.
              This is what gives them such an advantage over IPSs. Because IPSs
              are designed to interrogate all network traffic, they cannot
              analyze the application layer as thoroughly. WAFs not only detect
              attacks that are known to occur in web application environments,
              they also detect (and can prevent) new unknown types of attacks.
              By watching for unusual or unexpected patterns in the traffic they
              can alert and/or defend against unknown attacks. For example- if a
              WAF detects that the application is returning much more data than
              it is expected to, the WAF can block it and alert someone.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Webapp;
