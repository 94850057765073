import React from "react";
import "./Footer.scss";
import { useLocation } from "react-router-dom";

function Footer() {

  const location = useLocation();
  console.log(location, "loc");

  let bgColor;
  if (location.pathname == "/") {
    bgColor = "#3b0f9a";
  } else {
    bgColor = "#002538";
  }

  return (
    <div className="container">
      <section className="footer" style={{ backgroundColor: bgColor }}>
        <div className="footer-content">
          <div className="footer-content-logo">
            <img src="assets/images/landing-page/tsqatar-logo.png" alt="" />
            <div className="footer-content-logo_container">
              <div className="footer-logo-icon">
                <img src="assets/images/landing-page/phone-white.svg" alt="" />
                <p>+97444364001</p>
              </div>
              <div className="footer-logo-icon">
                <img
                  src="assets/images/landing-page/envolop-white.svg"
                  alt=""
                />
                <p>info@tsqatar.com</p>
              </div>
            </div>
          </div>
          <div className="footer-content-box">
            <h5>Company</h5>
            <ul>
              <li>Home</li>
              <li>Shop</li>
              <li>Partners</li>
              <li>Contact Us</li>
              <li>Services & Situations</li>
            </ul>
          </div>
          <div className="footer-content-box">
            <h5>Services & Situations</h5>
            <ul>
              <li>PC Accessories</li>
              <li>IT Security Solution</li>
              <li>IT Power Solution</li>
              <li>Support Contract</li>
              <li>Structured Cabling</li>
            </ul>
          </div>
          <div className="footer-content-box-last">
            <h5>Get In Touch</h5>
            <p>
              TS Qatar Systems & Communications <br /> 24699 Ibn Seena Street{" "}
              <br /> Al-Munthaza <br /> Doha Qatar
            </p>
            <div className="footer-content-socialicons">
              <img src="assets/images/landing-page/facebook.svg" alt="" />
              <img src="assets/images/landing-page/twitter.svg" alt="" />
              <img src="assets/images/landing-page/instagram.svg" alt="" />
              <img src="assets/images/landing-page/linkedin.svg" alt="" />
            </div>
          </div>
        </div>
        <hr />
        <p className="footer-copyright">
          Copyright ©2022 All rights reserved | TS Qatar, Doha, Qatar
        </p>
      </section>
    </div>
  );
}
export default Footer;
