import React from "react";
import "./Contact.scss";

function Contact() {
  return (
    <div className="container-contact">
      <section class="contact">
        <div class="contact-left">
          <div class="contact-content">
            <h2>Let's talk</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Ipsum is simply.
            </p>
          </div>
          <div class="contact-address">
            <div class="contact-address-box">
              <img src="assets/images/landing-page/location.svg" alt="" />
              <p>
                {" "}
                <b>TS QATAR SYSTEM & COMMUNICATIONS</b> <br />
                24699 Ibn Seena Street Al-Munthaza <br />
                Doha Qatar
              </p>
            </div>
            <div class="contact-address-box">
              <img src="assets/images/landing-page/phone-white.svg" alt="" />
              <p>
                Sun-Thu 8 AM – 5:30 PM <br /> Saturday 9 AM – 2 PM <br />{" "}
                +97444364001
              </p>
            </div>
            <div class="contact-address-box">
              <img src="assets/images/landing-page/envolop-white.svg" alt="" />
              <p>mailto:info@tsqatar.com</p>
            </div>
          </div>
          <div class="contact-socialicons">
            <img src="assets/images/landing-page/facebook.svg" alt="" />
            <img src="assets/images/landing-page/twitter.svg" alt="" />
            <img src="assets/images/landing-page/instagram.svg" alt="" />
          </div>
        </div>

        <div class="contact-right">
          <div class="contact-table">
            <form>
              <div class="input-group">
                <label for="name">Name</label>
                <input type="text" id="name" required />
              </div>
              <div class="input-group">
                <label for="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div class="input-group">
                <label for="subject">Subject</label>
                <input type="text" id="subject" name="subject" required />
              </div>
              <div class="input-group">
                <label for="services">Choose a Service</label>
                <select id="services" name="services">
                  <option value="option1">Servers & Storages</option>
                  <option value="option2">PC & Accessories</option>
                  <option value="option3">Endpoint Security</option>
                  <option value="option4">Option 4</option>
                </select>
              </div>
              <div class="input-group">
                <label for="message">Message</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button>Submit</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Contact;
